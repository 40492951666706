.intro-text {
  font-family: "Courier New", monospace;
  font-size: 24px;
  color: #00ff00;
  overflow: hidden;
  /*white-space: nowrap;*/
  text-align: center;
  animation: type 3s steps(40, end) forwards;
}

.matrix-menu {
  position: fixed;
  bottom: 0;
  border: 1px solid green;
  width: auto;
  opacity: 80%;
  text-align: center;
  background-color: black;
  left: 0;
  right: 0;
  margin: 2%;
  border-radius: 0.5em;
}

.matrix-menu > ul > li {
  display: inline;
}
.matrix-menu.collapsed {
  border: none;
  opacity: 80%;
  left: 0;
  bottom: 10%;
}
.matrix-menu.h1 {
  opacity: 100%;
}

.matrix-menu.button {
  opacity: 100%;
}

.intro-text-no-anim {
  font-family: "Courier New", monospace;
  font-size: 24px;
  color: #00ff00;
  overflow: hidden;
  opacity: 100%;
  /*white-space: nowrap;*/
  /*animation: type 3s steps(40, end) forwards;*/
}
@keyframes type {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.cursor {
  height: 24px;
  width: 15px;
  background-color: #00ff00;
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

body {
  background-color: black;
}

.play-game-button.matrix-style {
  /* Add some basic styles for the button */
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  color: black;
  text-decoration: none;
  user-select: none;
  padding: 10px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 10px;
  opacity: 100%;

  /* Add the Matrix-style green color and flickering effect */
  background-color: #00ff00;
  border-radius: 0.5em;
  animation: matrix-flicker 1s infinite;
}

@keyframes matrix-flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto; /* Set the width to auto */
  height: auto; /* Set the height to auto */
  margin: auto;
}

.play-game-button.matrix-style:disabled {
  /* Add the disabled style for the button */
  pointer-events: none; /* Prevent the button from being clicked */
  opacity: 0.5; /* Reduce the button's opacity to indicate it is disabled */
  color: #00ff00; /* Set the text color to the Matrix-style green color */
  background-color: black; /* Set the background color to black */
}

.matrix-style {
  /* Add the Matrix-style green color and flickering effect */
  border: 4px solid #00ff00;
  background-color: #00ff00;
  max-height: auto;
  max-width: 50%;
  animation: matrix-flicker 1s infinite;
}

.matrix-image {
  /* Add the Matrix-style green color and flickering effect */
  /* border: 4px solid #00ff00;
  border-radius: 0.5em;
  background-color: #00ff00; */
  max-width: 80%;
  margin-left: 5%;
  /* animation: matrix-flicker 1s infinite;*/
}

.collapse-button {
  position: absolute;
  top: 0;
  right: 0;
}

.back-button {
  position: absolute;
  top: 0;
  left: 0;
}

.see-more-button {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0px;
  border: 2px solid #00ff00;
  border-width: 0 0 2px 2px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #00ff00;
  background-color: black;
  content: "\f063"; /* Font Awesome arrow-down icon */
  cursor: pointer;
  animation: matrix-glitch 1s infinite;
}
@keyframes matrix-glitch {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.gameTileWrapper {
  overflow-wrap: break-word;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
a {
  color: lightblue;
  text-decoration: none;
}

a:visited {
  color: burlywood;
  text-decoration: none;
}

input[type="text"] {
  background-color: rgba(255, 255, 255, 0.05);
  border: 2px solid green;
  color: green;
  font-size: 24px;
  height: 36px;
  margin: 10px;
  padding: 10 20px;
  width: auto;
}

.subscription {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.image-text {
  border: 2px solid #00ff00;
  margin-bottom: 10px;
  border-radius: 0.5em;
  font-size: 16px;
  padding: 10px;
  color: #00ff00;
}
