.gameTile { 
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: green;
    max-width: 150px;
    max-height: 400px;
    padding: 20px;
    border: green 1px solid;
    border-radius: 0.5rem;
    margin-top: 20px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
}
.gameText { 
    padding-top: 10px;
    text-align: center;
}
.disabledGame {
    cursor: default;
    opacity: 0.5;
}
