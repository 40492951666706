footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Courier New", Courier, monospace;
  border-top: 1px solid green;
  border-bottom: 1px solid green;
  padding-top: 10px;
}

.matrix-button {
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  outline: green;
  border: none;
  cursor: pointer;
  margin: 10px;
  opacity: 100%;

  background-color: yellow;
  border-radius: 0.5em;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  animation: marquee 30s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@media (max-width: 768px) {
  .marquee {
    animation: marquee-mobile 40s linear infinite;
  }
  @keyframes marquee-mobile {
    0% {
      transform: translateX(200%);
    }
    100% {
      transform: translateX(-200%);
    }
  }
}
