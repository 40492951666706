.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  max-width: 100em;
  max-height: 200em;
  background: black;
  color: green;
  z-index: 10;
  border-radius: 2px;
  border: 2px solid green;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  background: black;
  overflow: hidden;
  border-radius: 2px;
}

.heading {
  margin: 0;
  padding: 10px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  text-align: center;
}

.modalActions {
  /* position: absolutex */
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  /* Add some basic styles for the button */
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  color: black;
  text-decoration: none;
  user-select: none;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 10px;
  opacity: 100%;

  /* Add the Matrix-style green color and flickering effect */
  background-color: #00ff00;
  border-radius: 0.2em;
  cursor: pointer;
  font-size: 18px;
}

/* .closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
} */

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}
