header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-family: "Courier New", Courier, monospace;
  border: 1px solid green;
}

.header-button {
  margin: 0;
  padding: 10px 20px;
  font-size: 24px;
  letter-spacing: 1px;
  background-color: black;
  border: none;
  /*text-transform: uppercase;*/
  color: #00ff00;
  cursor: pointer;
  text-shadow: 2px 2px green;
}
